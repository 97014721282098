require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")

// Stimulus
require("controllers")

// Tailwind CSS
require("stylesheets/application")

// clipboard.js
window.ClipboardJS = require("clipboard")
