import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "submitButton" ]

  subdue() {
    // since button is an image, it does not have the same characteristics as a
    // standard form button, nor does it support rails ujs `disable-with`

    this.submitButtonTarget.classList.add('opacity-50')
  }
}
