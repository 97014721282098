import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "form", "card", "errors", "billingName", "submitButton" ]

  connect() {
    this.stripe    = Stripe(this.data.get("public-key"))
    const elements = this.stripe.elements()

    this.card = elements.create("card", { style: {
      base: {
        color: '#4A5568',
        fontSize: '18px'
      }
    }})
    this.card.mount(this.cardTarget)
  }

  change(event) {
    if (event.error) {
      this.errorsTarget.textContent = event.error.message
    } else {
      this.errorsTarget.textContent = ""
    }
  }

  setHiddenField(paymentMethod) {
    const hiddenInput = document.createElement("input")

    hiddenInput.setAttribute("type", "hidden")
    hiddenInput.setAttribute("name", "user[stripe_payment_method_id]")
    hiddenInput.setAttribute("value", paymentMethod.id)
    this.formTarget.appendChild(hiddenInput)

    this.formTarget.submit()
  }

  submit(event) {
    event.preventDefault()

    this.stripe.createPaymentMethod({
      type: 'card',
      card: this.card,
      billing_details: {
        name: this.billingNameTarget
      }
    }).then(function(result){
      if (result.error) {
        this.errorsTarget.textContent = result.error.message
        this.submitButtonTarget.disabled = false
        this.submitButtonTarget.value = "Sign Up"
      } else {
        this.setHiddenField(result.paymentMethod)
      }
    }.bind(this))
  }
}