import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "iframe" ];

  connect() {
    if (this.data.get('active') != 'true') return;

    setInterval(function() {
      this.fetch();
    }.bind(this), this.data.get('interval'));
  }

  update(json) {
    let currentVideoId = this.data.get("videoId");
    let latestVideoId = json["latest_video"]["external_id"];

    if(currentVideoId != latestVideoId) {
      let latestVideoUrl = json["latest_video"]["embed_url"];

      this.data.set("videoId", latestVideoId);
      this.iframeTarget.src = latestVideoUrl;
    }
  }

  fetch() {
    var req = new XMLHttpRequest();
    req.overrideMimeType("application/json");
    req.open('GET', this.data.get('url'), true);
    req.onload  = function() {
       var json = JSON.parse(req.responseText);
       this.update(json);
    }.bind(this);
    req.send(null);
  }
}
