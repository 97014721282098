import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "source", "trigger", "check" ]

  success() {
    this.checkTarget.classList.remove('hidden');
    this.checkTarget.classList.add('animate-ping-once');
    this.retriggerAnimation(this.checkTarget);
  }

  connect() {
    this.checkTarget.classList.add('hidden');

    let clipboard = new ClipboardJS(this.triggerTarget, {
      text: function(trigger) {
        return this.sourceTarget.value;
      }.bind(this)
    });

    clipboard.on('success', function(e) {
      this.success();
    }.bind(this));

    this.checkTarget.addEventListener('animationend', function(e) {
      this.checkTarget.classList.add('hidden');
    }.bind(this));
  }

  // if animation is a one-time animation, it needs reset
  // so that it will display again.
  retriggerAnimation(el) {
    el.style.animation = 'none';
    el.offsetHeight;
    el.style.animation = null;
  }
}
